<template>
  <div class="homeContainer">
    <div class="topBanner">
      <div style="position: absolute;z-index:2;width: 100%;height: 100%">
        <img src="../assets/topBanner.png" style="width: 100%;height: 100%;object-fit: fill"/>
      </div>
      <div style="z-index: 3;display: flex;flex-direction: row;align-items: center;padding-left: 43px">
        <img v-if="avatar_url ==undefined || avatar_url == ''" src="../assets/default_avatar.png"
             style="width: 62px;height: 62px;border-radius: 31px">
        <img v-else :src="avatar_url" style="width: 62px;height: 62px;border-radius: 31px">
        <div style="margin-left: 10px;display: flex;flex-direction: column">
          <div style="font-size: 16px;color: white;font-weight: 600">{{ student_name }}</div>
          <div style="font-size: 16px;color: white;font-weight: 600">{{ student_school }}</div>
        </div>
        <div style="margin-left: 40px">
          <div style="font-size: 2vw;color: white;font-weight: 600">2021-2022 FBLA China<br>
            National Leadership Conference and Economics Challenge
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="mainLeft">
        <div v-if="user_type==='student'" class="mainLeftContent">
          <div v-for="item in nav" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
          <div class="content-logout" @click="changeView('')">
            <img src="../assets/nav/tuichu.png" class="iconImg"/>
            退出登录
          </div>
        </div>
        <div v-if="user_type==='educator'" class="mainLeftContent">
          <div v-for="item in navContact" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="user_type==='employee'" class="mainLeftContent">
          <div v-for="item in navEmployee" :key="item.name" class="navOutBox" @click="changeView(item)">
            <div :class="{'navInBox':true,'selected':currentNav===item.router}">
              <img :src="item.selected" class="iconImg" v-if="currentNav===item.router"/>
              <img :src="item.unselected" class="iconImg" v-else/>
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="mainRight">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {logout, getUserType, getUserName, getAccountName,getAvatarUrl} from '../utils/store'

export default {
  data() {
    return {
      nav: [
        {
          name: '官方公告',
          selected: require('../assets/nav/peixun1.png'),
          unselected: require('../assets/nav/peixun.png'),
          router: '/announcements'
        },
        {
          name: '挑战项目',
          selected: require('../assets/nav/bisai1.png'),
          unselected: require('../assets/nav/bisai.png'),
          router: '/sessionlist'
        },
        {
          name: '成绩排行',
          selected: require('../assets/nav/chengji1.png'),
          unselected: require('../assets/nav/chengji.png'),
          router: '/score'
        },
        {
          name: '个人信息',
          selected: require('../assets/nav/geren1.png'),
          unselected: require('../assets/nav/geren.png'),
          router: '/personalinfo'
        },
        {
          name: '消息',
          selected: require('../assets/nav/xiaoxi1.png'),
          unselected: require('../assets/nav/xiaoxi.png'),
          router: '/message'
        },
        {
          name: '挑战成绩',
          selected: require('../assets/nav/award1.png'),
          unselected: require('../assets/nav/award.png'),
          router: '/award'
        },
        {
          name: '演示安排',
          selected: require('../assets/nav/navRoomSelect.png'),
          unselected: require('../assets/nav/navRoom.png'),
          router: '/presentationList'
        },
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: '比赛项目',
          selected: require('../assets/nav/bisai1.png'),
          unselected: require('../assets/nav/bisai.png'),
          router: '/teacherlist'
        },
        {
          name: '个人信息',
          selected: require('../assets/nav/geren1.png'),
          unselected: require('../assets/nav/geren.png'),
          router: '/personalinfo'
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navEmployee: [
        {
          name: '赛前培训',
          selected: require('../assets/nav/peixun1.png'),
          unselected: require('../assets/nav/peixun.png'),
          router: '/peixunadmin'
        },
        {
          name: '比赛项目',
          selected: require('../assets/nav/bisai1.png'),
          unselected: require('../assets/nav/bisai.png'),
          router: '/employeelist'
        },
        {
          name: '个人信息',
          selected: require('../assets/nav/geren1.png'),
          unselected: require('../assets/nav/geren.png'),
          router: '/personalinfo'
        },
        {
          name: '监考',
          selected: require('../assets/nav/bisai1.png'),
          unselected: require('../assets/nav/bisai.png'),
          router: '/examadminlist'
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      user_type: getUserType(),
      currentNav: '/peixun',
      student_name: '',
      student_school: '',
      application_project: '',
      avatar_url: ''
    }
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
    this.student_name = getUserName()
    this.student_school = getAccountName()
    this.avatar_url = getAvatarUrl()
    this.currentNav = this.$route.path
  },
  methods: {
    logout() {
      logout()
      location.reload()
    },
    changeView(item) {
      if (item.router) {
        this.currentNav = item.router
        this.$router.push(item.router)
      } else {
        this.$confirm('Are you sure you want to exit?', 'Tips', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          this.logout()
        })
      }

    }
  }
}
</script>

<style scoped="scoped" lang="less">

.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #577BBC;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.homeContainer {
  .topBanner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 18vh;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
  }

  .main {
    margin: 0;
    margin-top: -1px;
    padding: 0;
    display: flex;
    height: 82vh;
    position: relative;

    .mainBack {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    .mainLeft {
      //#FF9900;
      background-color: #0E409F;
      width: 380px;

      .mainLeftContent {
        .navOutBox {
          width: 380px;
          height: 10vh;
          background: #0E409F;
          display: flex;
          flex-direction: row;
          cursor: pointer;

          .navInBox {
            width: 380px;
            padding-left: 70px;
            height: 10vh;
            align-items: center;
            justify-content: flex-start;
            background: #0E409F;
            display: flex;
            font-size: 2vh;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: rgba(255, 255, 255, 0.6);

            .iconImg {
              height: 3vh;
              margin-right: 6px;
            }
          }

          .selected {
            background-color: #577BBC;
            color: white;
          }
        }
      }
    }

    .mainRight {
      background-image: url("../assets/magin_right_bg.png");
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: cover;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
</style>